<template>
  <div>
    <el-collapse-transition>
      <!--搜索框-->
      <TableSearch v-show="showSearch" :query-params="queryParams" @search="handleSearch" @reset="handleReset">
        <el-form :model="queryParams" ref="queryForm" label-suffix=":" label-width="100px">

          <el-row :gutter="10">
            <el-col :xs="24" :sm="12" :md="8" :lg="8" :xl="8">
              <el-form-item prop="title" label="文章标题">
                <el-input v-model="queryParams.title" placeholder="请输入文章标题" clearable/>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item prop="type" label="文章类型">
                <el-select v-model="queryParams.type" clearable placeholder="请选择文章类型">
                  <el-option
                      v-for="dict in dict.article_type"
                      :key="dict.dictValue"
                      :label="dict.dictLabel"
                      :value="dict.dictValue"/>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item prop="isShow" label="是否展示">
                <el-select v-model="queryParams.isShow" clearable placeholder="请选择是否展示">
                  <el-option
                      v-for="dict in dict.sys_yes_no"
                      :key="dict.dictValue"
                      :label="dict.dictLabel"
                      :value="dict.dictValue"/>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="6" :lg="6" :xl="6">
              <el-form-item prop="isProfile" label="学院简介">
                <el-select v-model="queryParams.isProfile" clearable placeholder="请选择是否学院简介">
                  <el-option
                      v-for="dict in dict.sys_yes_no"
                      :key="dict.dictValue"
                      :label="dict.dictLabel"
                      :value="dict.dictValue"/>
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :xs="24" :sm="12" :md="8" :lg="6" :xl="6">
              <el-form-item label="发布日期">
                <el-date-picker
                    v-model="dateRange"
                    type="daterange"
                    align="right"
                    unlink-panels
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd">
                </el-date-picker>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </TableSearch>
    </el-collapse-transition>

    <div>
      <el-button style="width: 80px" size="mini" type="success" @click="handleSave">添加</el-button>
      <el-button style="width: 80px" size="mini" type="primary" @click="showSearch = !showSearch">{{showSearch ? '隐藏搜索' : '显示搜索'}}</el-button>
    </div>

    <!--列表-->
    <div>
      <el-table
          :data="tableData"
          stripe
          style="width: 100%; font-size: 15px"
          size="medium"
          @sort-change="handleSortChange"
          :default-sort = "{prop: 'sort', order: 'ascending'}"
      >
        <el-table-column type="index" width="50"/>

        <el-table-column prop="title" label="文章标题" show-overflow-tooltip/>

        <el-table-column label="封面" width="120">
          <template slot-scope="scope">
            <el-image
                style="width: 50px; height: 30px;"
                :src="scope.row.imageUrl"
                :preview-src-list="[scope.row.imageUrl]"
            >
            </el-image>
          </template>
        </el-table-column>

        <el-table-column label="文章类型" width="130">
          <template slot-scope="scope">
            <dict-tag :options="dict.article_type" :value="scope.row.type"></dict-tag>
          </template>
        </el-table-column>

        <el-table-column label="是否展示" width="130">
          <template slot-scope="scope">
            <dict-tag :options="dict.sys_yes_no" :value="scope.row.isShow"></dict-tag>
          </template>
        </el-table-column>

        <el-table-column prop="views" label="阅读次数" width="130" sortable="custom"/>

        <el-table-column prop="sort" label="排序" width="130" sortable="custom"/>

        <!--<el-table-column label="是否置顶">-->
        <!--  <template slot-scope="scope">-->
        <!--    <dict-tag :options="dict.sys_yes_no" :value="scope.row.isTop"></dict-tag>-->
        <!--  </template>-->
        <!--</el-table-column>-->

        <el-table-column prop="pubdate" label="发布日期" width="130" sortable="custom"/>

        <el-table-column label="操作" width="150">
          <template slot-scope="scope">
            <el-button type="text" @click="handleUpdate(scope.row)">修改</el-button>&nbsp;
            <el-dropdown trigger="click">
              <span class="el-dropdown-link">
                <span class="color-primary">更多<i class="el-icon-arrow-down el-icon--right"></i></span>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-if="scope.row.isProfile"><span class="color-danger" @click="handleCancelSetIsProfile(scope.row)">取消学院简介</span></el-dropdown-item>
                <el-dropdown-item v-else><span class="color-primary" @click="handleSetIsProfile(scope.row)">设置为学院简介</span></el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>&nbsp;
            <el-button type="text" class="color-danger" @click="handleDelete(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <pagination
          v-show="queryParams.total>0"
          :total="queryParams.total"
          :page.sync="queryParams.curPage"
          :limit.sync="queryParams.pageSize"
          @pagination="getList"
      />
    </div>

    <!--新增、编辑弹框-->
    <CommonDialog ref="dialog" :title="dialogInfo.title" :type="dialogInfo.type" :disabled="dialogInfo.disabled"
                  @confirm="handleSubmitConfirm" @close="handleClose" width="1000px" top="100">

      <el-row type="flex" justify="center">
        <el-col :span="12">
          <div style="width: 400px">
            <el-form ref="form" :model="formData" :rules="rules" label-width="80px">
              <el-form-item label="" prop="id">
                <el-input v-show="false" v-model="formData.id"></el-input>
              </el-form-item>

              <el-form-item label="文章标题" prop="title">
                <el-input v-model="formData.title" placeholder="文章标题" clearable/>
              </el-form-item>

              <el-form-item label="文章类型" prop="type">
                <el-select v-model="formData.type" clearable placeholder="请选择文章类型">
                  <el-option
                      v-for="dict in dict.article_type"
                      :key="Number(dict.dictValue)"
                      :label="dict.dictLabel"
                      :value="Number(dict.dictValue)"/>
                </el-select>
              </el-form-item>

              <el-form-item label="是否展示" prop="isShow">
                <el-select v-model="formData.isShow" clearable placeholder="请选择是否展示">
                  <el-option
                      v-for="dict in dict.sys_yes_no"
                      :key="Number(dict.dictValue)"
                      :label="dict.dictLabel"
                      :value="Number(dict.dictValue)"/>
                </el-select>
              </el-form-item>

              <el-form-item label="序号" prop="sort">
                <el-input type="number" min="0" v-model="formData.sort" placeholder="请输入阅读次数" clearable/>
              </el-form-item>

              <el-form-item label="阅读次数" prop="views">
                <el-input type="number" min="0" v-model="formData.views" placeholder="请输入阅读次数" clearable/>
              </el-form-item>

              <el-form-item label="发布日期" prop="pubdate">
                <el-date-picker
                    style="width: 100%"
                    v-model="formData.pubdate"
                    type="date"
                    placeholder="选择发布日期"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    :default-time="formData.pubdate">
                </el-date-picker>
              </el-form-item>

              <el-form-item label="" prop="content">
                <el-input v-show="false" v-model="formData.content"></el-input>
              </el-form-item>

              <el-form-item label="" prop="imageUrl">
                <el-input v-show="false" v-model="formData.imageUrl"></el-input>
              </el-form-item>

              <el-form-item label="文章封面" prop="fileId">
                <el-input type="number" v-show="false" v-model="formData.fileId"></el-input>
                <el-upload
                    class="avatar-uploader"
                    :headers="{Authorization: token}"
                    :action="uploadUrl"
                    :show-file-list="false"
                    :multiple="false"
                    :on-success="handleImageSuccess"
                    :before-upload="beforeImageUpload">
                  <img v-if="formData.imageUrl" :src="formData.imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
            </el-form>
          </div>
        </el-col>
        <el-col :span="12">
          <div style="width: 100%">
            <Tinymce
                ref="editor"
                v-model="formData.content"
                width="500px"
                :height="`${888 < (innerHeight-160) ? 888 : (innerHeight-160)}px`"
                :resize="false"
                base-url="/web"
            />
          </div>
        </el-col>
      </el-row>
    </CommonDialog>

  </div>
</template>

<script>
import TableSearch from "@/components/TableSearch/TableSearch.vue";
import Pagination from "@/components/Pagination/index.vue";
import CommonDialog from "@/components/CommonDialog.vue";
import DictTag from "@/components/DictTag/index.vue";
import store from "@/store";
import Tinymce from "@/components/Tinymce/Tinymce.vue";

export default {
  name: "index",
  dicts: ['article_type', 'sys_yes_no'],
  components: {Tinymce, DictTag, CommonDialog, Pagination, TableSearch},
  data() {
    return {
      queryParams: {
        total: 0,
        curPage: 1,
        pageSize: 10,
        title: '',
        type: '',
        isShow: null,
        isProfile: null,
        sortColumn: '',
        sortOrder: '',
      },
      tableData: [],
      showSearch: false,
      formData: {
        id: null,
        title: '',
        type: null,
        isShow: null,
        content: '',
        fileId: null,
        imageUrl: '',
        pubdate: '',
        views: 0,
        sort: 0,
      },

      token: store.state.userinfo.token,
      uploadUrl: process.env.VUE_APP_BASE_URL + '/file/upload',
      innerHeight: window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight,
      rules: {
        title: [
          {required: true, message: '请输入文章标题', trigger: 'blur'},
          {min: 1, max: 50, message: '1~100字符', trigger: 'blur'}
        ],
        type: [
          {required: true, message: '请选择文章类型', trigger: ['blur', 'change']}
        ],
        //isTop: [
        //  {required: true, message: '请选择是否置顶', trigger: ['blur', 'change']}
        //],
        isShow: [
          {required: true, message: '请选择是否展示', trigger: ['blur', 'change']}
        ],
      },
      dialogInfo: {
        title: '',
        type: '',
        disabled: false,
      },
      dateRange: [],
    }
  },
  mounted() {
    this.getList()
  },

  methods: {
    getList() {
      this.$api.article.page(this.$addDateRange(this.queryParams, this.dateRange)).then(res => {
        this.tableData = res.list
        this.queryParams.total = res.total
        this.queryParams.pages = res.pages
      })
    },

    handleSortChange(e) {
      this.queryParams = this.$handleSort(this.queryParams, e)
      this.getList()
    },

    resetFormData() {
      this.$resetForm('form')
    },

    handleSave() {
      this.dialogInfo.title = '新 增'
      this.dialogInfo.type = 'success'
      this.$refs.dialog.show()
    },

    handleClose() {
      this.resetFormData()
    },

    handleView() {

    },

    handleUpdate(row) {
      this.$api.article.get(row.id).then(res => {
        this.dialogInfo.title = '修 改'
        this.dialogInfo.type = 'warning'
        this.$refs.dialog.show()
        this.$nextTick(() => {
          this.formData = res
        })
      })
    },

    handleDelete(row) {
      this.$dialog({
        type: 'danger',
        content: `确认删除“<b class="color-danger">${row.title}</b>”？`
      }).then(() => {
        this.$api.article.del(row.id).then(() => {
          this.getList()
          this.$message.success('删除成功')
        })
      })
    },

    handleSetIsProfile(row) {
      if (row && row.id) {
        this.$dialog({
          type: 'warning',
          content: `确认将“<b class="color-danger">${row.title}</b>”设置为学院简介？`
        }).then(() => {
          this.$api.article.setIsProfile(row.id).then(() => {
            this.getList()
            this.$message.success('设置成功')
          })
        })
      }
    },

    handleCancelSetIsProfile(row) {
      if (row && row.id) {
        this.$dialog({
          type: 'warning',
          content: `确认将“<b class="color-danger">${row.title}</b>”取消设置为学院简介？`
        }).then(() => {
          this.$api.article.cancelSetIsProfile(row.id).then(() => {
            this.getList()
            this.$message.success('设置成功')
          })
        })
      }
    },

    handleSubmitConfirm() {
      this.$refs['form'].validate((valid) => {
        if (valid) {
          if (this.formData.id) {
            this.$api.article.update(this.formData).then(res => {
              this.$message.success('修改成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          } else {
            this.$api.article.save(this.formData).then(res => {
              this.$message.success('新增成功')
              this.$refs.dialog.close()
              this.getList()
            }).catch(() => {
              this.$refs.dialog.stopLoading()
            })
          }
        } else {
          this.$message.warning('请填写完整')
          this.$refs.dialog.stopLoading()
        }
      })
    },

    handleImageSuccess(res, file) {
      if (Number(res.code) !== 200) {
        this.$message.error('上传失败，请联系管理员')
        return
      }
      this.formData.fileId = res.data.id
      this.formData.imageUrl = URL.createObjectURL(file.raw);
    },

    beforeImageUpload(file) {
      const isJPG = file.type === 'image/jpeg';
      const isLt6M = file.size / 1024 / 1024 < 5;

      if (!isJPG) {
        this.$message.error('上传图片只能是 JPG 格式!');
      }
      if (!isLt6M) {
        this.$message.error('上传图片大小不能超过 5MB!');
      }
      return isJPG && isLt6M;
    },

    handleSearch() {
      this.queryParams.curPage = 1
      this.getList()
    },

    handleReset() {
      this.dateRange = []
      this.$resetForm('queryForm')
      this.$nextTick(() => {
        this.handleSearch()
      })
    },
  }
}
</script>

<style scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

::v-deep .avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

::v-deep .avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 320px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

::v-deep .avatar {
  width: 320px;
  height: 178px;
  display: block;
}

</style>
